var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.goTopVisible,
      expression: "goTopVisible"
    }],
    staticClass: "goTop",
    on: {
      "click": _vm.goTop
    }
  }, [_vm._v("위로")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }