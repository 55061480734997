<template>
  <transition name="fade" mode="out-in">
    <button class="goTop" v-show="goTopVisible" @click="goTop">위로</button>
  </transition>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ".DAV-blockClass"
    }
  },
  data() {
    return {
      goTopVisible: false
    };
  },
  methods: {
    // scroll event
    onScroll(e) {
      this.goTopVisible = e.target.scrollTop > 100;
    },
    // 상단으로 이동
    goTop() {
      document.querySelector(this.className).scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    // scroll event 추가
    if (document.querySelector(this.className)) {
      document
        .querySelector(this.className)
        .addEventListener("scroll", this.onScroll);
    }
  },
  beforeDestroy() {
    // scroll event 삭제
    if (document.querySelector(this.className)) {
      document
        .querySelector(this.className)
        .removeEventListener("scroll", this.onScroll);
    }
  }
};
</script>

<style lang="scss">
.goTop {
  z-index: 11;
  position: fixed;
  right: 25px;
  bottom: 16px;
  width: 44px;
  height: 44px;
  text-indent: -9999px;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  background-color: #f6f6f6;
  background-image: url($path + "bul_up.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
</style>
